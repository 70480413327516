import { useScrollLock } from '@vueuse/core';
import { watch } from 'vue';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { PostMessages } from '@/enums';
import { usePostMessageHandler } from '@/utils/composables/usePostMessageHandler';

export const useBlockScroll = () => {
	const isLocked = useScrollLock(document.body);
	const { activeModal } = useModalStorage();

	watch(
		() => activeModal.value.name,
		(modalName) => {
			isLocked.value = Boolean(modalName);
		}
	);

	usePostMessageHandler((event: MessageEvent) => {
		if (event.data.message === PostMessages.SCROLL_BLOCK) {
			isLocked.value = event.data.value;
		}
	});
};
