import { defineStore } from 'pinia';
import {
	authCheckEmail,
	authForgotPassword,
	authLogin,
	authLoginWithUrl,
	authRegister,
	authResendVerificationEmail,
	authResetPassword,
	authSendOneTimeToken,
	authSetPassword,
	authSocialCallback,
	authSocialLoginToExisting,
	authSocialRegister,
	authSocialRegisterWithSignature,
	authVerifyCode,
	authVerifyEmail,
} from '@/api';
import type { AxiosError, AxiosResponse } from 'axios';
import type {
	AuthPayload,
	ErrorResponse,
	LoginResponse,
	ResetPasswordPayload,
	SocialAuthCallback,
	SocialAuthPayload,
	SocialCallbackResponse,
} from '@/types';
import {
	getCountryCode,
	getErrorResponse,
	getLanguage,
	getLanguageNative,
	getSocialAuthErrorResponse,
} from '@/utils/helpers';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { sendErrorInfo } from '@/utils/errorCatching';
import { handleUserLocale } from '@/i18n';
import router from '@/router';
import { useAppStore } from '@/stores/app';
import { useUserStore } from '@/stores/user';
import { SignatureActions, TrackingEvents } from '@/enums';
import {
	aIDStorage,
	oneClickSurveyRewardStorage,
	signatureLoginActionStorage,
	sslhStorage,
} from '@/utils/local-storage';

interface AuthState {
	email: string;
	token: string;
	message: string;
	loading: boolean;
	emailNotVerified: boolean;
	error: ErrorResponse | null;
	topNavVisibility: boolean;
	mobileNavVisibility: boolean;
	currentLocalization: {
		localization: string;
		country: string;
		language: string;
	};
	checkedEmail: string;
	socials: string[];
	checkedEmailExists: boolean;
	resetPasswordSent: boolean;
	registrationEmailSent: boolean;
	socialAuthData: SocialCallbackResponse | null;
	linkExistingToSocial: boolean;
	codeError: ErrorResponse | null;
	adminId: string | null;
	deviceId: string;
	isSuperAdmin: boolean;
}

export const useAuthStore = defineStore('auth', {
	state: (): AuthState => ({
		email: '',
		token: '',
		message: '',
		loading: false,
		emailNotVerified: false,
		error: null,
		topNavVisibility: true,
		mobileNavVisibility: true,
		currentLocalization: {
			localization: '',
			country: '',
			language: '',
		},
		checkedEmail: '',
		socials: [],
		checkedEmailExists: false,
		resetPasswordSent: false,
		registrationEmailSent: false,
		socialAuthData: null,
		linkExistingToSocial: false,
		codeError: null,
		adminId: aIDStorage.value,
		deviceId: '',
		isSuperAdmin: false,
	}),

	getters: {
		isAuthorized(): boolean {
			return !!this.token;
		},
		isTopNavVisible(): boolean {
			return this.topNavVisibility;
		},
		isMobileNavVisible(): boolean {
			return this.mobileNavVisibility;
		},
		localization(): string {
			return this.currentLocalization.localization;
		},
		country(): string {
			return this.currentLocalization.country;
		},
		language(): string {
			return this.currentLocalization.language;
		},
		localizationText(): string {
			if (this.currentLocalization.localization === 'fil-ph') {
				return `Filipino`;
			} else {
				return `${getLanguageNative(this.currentLocalization.language)}`;
			}
		},
		authUserEmail(): string {
			return this.checkedEmail;
		},
		isResetPasswordSent(): boolean {
			return this.resetPasswordSent;
		},
		authErrors(): ErrorResponse | null {
			return this.error;
		},
		isRegistrationEmailSend(): boolean {
			return this.registrationEmailSent;
		},
		isEmailNotVerified(): boolean {
			return this.emailNotVerified;
		},
		usersSocials(): string[] {
			return this.socials;
		},
		userSocialAuthData(): SocialCallbackResponse | null {
			return this.socialAuthData;
		},
		isLinkExistingToSocial(): boolean {
			return this.linkExistingToSocial;
		},

		codeErrorContent(): ErrorResponse | null {
			return this.codeError || null;
		},

		getAdminId(): string | null {
			return this.adminId || null;
		},

		userAuthToken: (state) => state.token,
		mobileDeviceId: (state) => state.deviceId,
	},

	actions: {
		setEmailNotVerified(value: boolean): void {
			this.emailNotVerified = value;
		},

		setRegistrationEmailSent(value: boolean): void {
			this.registrationEmailSent = value;
		},

		setResetPasswordSent(value: boolean): void {
			this.resetPasswordSent = value;
		},

		setToken(token: string): void {
			const { setAuthToken } = useAppCookies();
			setAuthToken(token);
			this.token = token;
		},

		setTopNavigationVisibility(value: boolean): void {
			this.topNavVisibility = value;
		},
		setMobileNavigationVisibility(value: boolean): void {
			this.mobileNavVisibility = value;
		},
		setLocalization(value: string): void {
			this.currentLocalization.localization = value;
			this.currentLocalization.language = getLanguage(value);
			this.currentLocalization.country = getCountryCode(value);
		},

		setCheckedEmail(value: string) {
			this.checkedEmail = value;
		},
		setCheckedEmailExists(value: boolean) {
			this.checkedEmailExists = value;
		},
		setUsedSocials(value: string[]) {
			this.socials = value;
		},

		setEmail(email: string): void {
			this.email = email;
		},

		setAdminId(adminId: string): void {
			aIDStorage.value = adminId;
			this.adminId = adminId;

			if (!adminId) {
				this.isSuperAdmin = false;
			}
		},

		setLinkExistingToSocial(value: boolean): void {
			this.linkExistingToSocial = value;
		},

		async setAccountData(data: LoginResponse): Promise<void> {
			this.token = data.token;
			this.email = data.email;
			const { setAuthToken } = useAppCookies();
			setAuthToken(data.token);
			await handleUserLocale(data.locale || null);
			const appStore = useAppStore();
			await appStore.setUser({
				language: data.locale || '',
			});
			await router.replace({ name: 'surveys' });
		},

		async setSocialAuthCallbackData(
			data: SocialCallbackResponse,
			nextRoute: string
		): Promise<void> {
			this.setCheckedEmail(data.email);
			this.setUsedSocials(data.socials);
			this.socialAuthData = data;
			await router.replace({ name: nextRoute });
		},

		async register(payload: AuthPayload): Promise<void> {
			try {
				this.loading = true;
				this.error = null;
				await authRegister({
					...payload,
					selected_country: this.country,
					selected_language: this.language,
				});
				this.setRegistrationEmailSent(true);
				const appStore = useAppStore();
				await appStore.trackEvent(TrackingEvents.SIGN_UP);
				await router.push({ name: 'signup-complete' });
			} catch (error) {
				this.error = getErrorResponse(error);
			} finally {
				this.loading = false;
			}
		},

		async resendEmail(): Promise<AxiosResponse | undefined> {
			try {
				this.loading = true;
				this.error = null;
				return await authResendVerificationEmail(this.authUserEmail);
			} catch (error) {
				this.error = getErrorResponse(error);
			} finally {
				this.loading = false;
			}
		},

		setDefaultErrorData(error: unknown): void {
			const errorResponse = getErrorResponse(error);
			if ('errors' in errorResponse) {
				this.error = getErrorResponse(error);
			} else {
				this.error = {
					message: errorResponse.message,
					sub_message: errorResponse?.sub_message || '',
				};
			}
		},

		setVerificationCodeError(error: unknown): void {
			this.codeError = getErrorResponse(error);
		},

		setLoginErrorData(email: string, error: unknown): void {
			const { response, message } = error as AxiosError;
			if (response?.status === 403) {
				this.emailNotVerified = true;
				this.message = message;
				this.email = email;
			} else {
				this.setDefaultErrorData(error);
			}
		},

		async login(payload: SocialAuthPayload): Promise<void> {
			try {
				this.loading = true;
				this.error = null;
				const data = await authLogin(payload);
				await this.setAccountData(data);
			} catch (error) {
				this.setLoginErrorData(payload.provider, error);
			} finally {
				this.loading = false;
			}
		},

		async verifyCode(code: string): Promise<void> {
			try {
				this.loading = true;
				this.error = null;
				const data = await authVerifyCode(code, this.checkedEmail);
				const appStore = useAppStore();
				await appStore.trackEvent(TrackingEvents.SIGN_UP_CONFIRMED);
				await this.setAccountData(data);
			} catch (error) {
				this.setVerificationCodeError(error);
			} finally {
				this.loading = false;
			}
		},

		async forgotPassword(email: string): Promise<AxiosResponse | undefined> {
			try {
				this.loading = true;
				this.error = null;
				const response = await authForgotPassword(email);
				this.setResetPasswordSent(true);
				return response;
			} catch (error) {
				this.setDefaultErrorData(error);
			} finally {
				this.loading = false;
			}
		},

		async resetPassword(payload: ResetPasswordPayload): Promise<void> {
			try {
				this.loading = true;
				this.error = null;
				const data = await authResetPassword(payload);

				if (data.message) {
					notify({ body: data.message });
				}

				if (data.status === 200) {
					await this.setAccountData(data);
				}
			} catch (error) {
				await router.replace({ name: 'app-login' });
				this.setDefaultErrorData(error);
				notify({
					body: getErrorResponse(error).message,
				});
			} finally {
				this.loading = false;
			}
		},

		async removeToken(): Promise<void> {
			const { removeAuthToken } = useAppCookies();
			removeAuthToken();
			this.token = '';
			this.setAdminId('');
		},

		// TODO: Remove it in September approx, when all new users will use signature-login approach
		async verifyEmail(authUrl: string): Promise<void> {
			try {
				const { token, email, message, status } =
					await authVerifyEmail(authUrl);

				if (status === 200) {
					const appStore = useAppStore();
					await appStore.trackEvent(TrackingEvents.SIGN_UP_CONFIRMED);
					await this.removeToken();
					this.setToken(token);
					this.setEmail(email);
					notify({ body: message });
					await router.replace({ name: 'surveys' });
				} else {
					await router.replace({ name: 'app-login' });
				}
			} catch (error) {
				await router.replace({ name: 'app-login' });
				sendErrorInfo(error);
			}
		},

		async loginWithToken(
			token: string,
			email: string,
			adminId?: string,
			isSuperAdmin?: boolean
		): Promise<void> {
			try {
				this.setToken(token);
				this.setEmail(email);

				if (adminId) {
					this.setAdminId(adminId);
				}

				if (isSuperAdmin) {
					this.isSuperAdmin = true;
				}
				await router.replace({ name: 'surveys' });
			} catch (error) {
				await router.replace({
					name: 'app-login',
				});
			}
		},

		async handleSignatureLoginAction(data: LoginResponse): Promise<void> {
			const appStore = useAppStore();
			const notifyAndRedirect = async (data: LoginResponse) => {
				notify({ body: data.message ?? '', duration: 10000 });
				await router.replace({ name: data.redirect_url ?? 'surveys' });
			};
			if (data.action) {
				signatureLoginActionStorage.value = data.action;
				switch (data.action) {
					case SignatureActions.VERIFY_EMAIL:
						await appStore.trackEvent(TrackingEvents.SIGN_UP_CONFIRMED);
						break;
					case SignatureActions.ONE_CLICK_SURVEY:
						await appStore.trackEvent(TrackingEvents.ONE_CLICK_SURVEY_USED);
						oneClickSurveyRewardStorage.value = data?.reward_coin_value
							? data?.reward_coin_value.toString()
							: '';
						break;
					case SignatureActions.WEEKLY_SURVEY_INVITATION:
						if (data.sslh) {
							sslhStorage.value = data.sslh;
						}
						await notifyAndRedirect(data);
						return;
					case SignatureActions.ONE_TIME_TOKEN:
					case SignatureActions.EMAIL_TRANSFER:
						await notifyAndRedirect(data);
						return;
				}
			}
			await router.replace({ name: 'surveys' });
		},

		async loginWithOneTimeToken(authUrl: string): Promise<void> {
			try {
				const { status, data } = await authLoginWithUrl<LoginResponse>(authUrl);
				if (status === 200) {
					this.setToken(data.token);
					this.setEmail(data.email);
					await this.handleSignatureLoginAction(data);
				} else {
					await router.replace({ name: 'app-login' });
					if (data.message) {
						notify({ body: data.message });
					}
				}
			} catch (error) {
				await router.replace({ name: 'app-login' });
				sendErrorInfo(error);
			}
		},

		async checkEmail(email: string): Promise<void> {
			try {
				const { data } = await authCheckEmail(email);
				this.setCheckedEmail(data.email);
				this.setCheckedEmailExists(data.exists);
				// Check if provided email already exists in DB
				if (data.exists) {
					// If provided email used in accounts registered with social networks
					if (data.socials?.length) {
						this.setUsedSocials(data.socials);
						await router.push({
							name: 'socials',
						});
						// If no social accounts were used - use login with email flow
					} else {
						await router.push('signin');
					}
					// If account does not exists in DB - go to register user via email flow
				} else {
					await router.push('signup');
				}
			} catch (error) {
				this.setDefaultErrorData(error);
			}
		},

		async requestOneTimeLink(): Promise<AxiosResponse | undefined> {
			try {
				this.loading = true;
				this.error = null;
				return await authSendOneTimeToken(this.authUserEmail);
			} catch (error) {
				this.setDefaultErrorData(error);
			} finally {
				this.loading = false;
			}
		},

		async checkSocial(params: SocialAuthCallback) {
			try {
				this.error = null;

				const { status, data } = await authSocialCallback(params);

				// Everything is OK, account was previously created with social network, user can log in
				if (status === 200 && 'token' in data) {
					await this.setAccountData(data);
					// User does not exists in DB and use Google to register new account
				} else if (status === 202 && 'signed_url' in data) {
					await this.setSocialAuthCallbackData(data, 'signup-social');
				} else {
					await router.replace({ name: 'app-login' });
				}
			} catch (_error) {
				const { data, status } = getSocialAuthErrorResponse(_error);

				// User try to log in with social account
				// and email same that was previously registered in normal email flow (accounts will be linked)
				if (status === 409 && data.socials.length === 0) {
					this.setLinkExistingToSocial(true);
					await this.setSocialAuthCallbackData(data, 'signin');
				}
				// User already created account with Facebook and then try to register new via Google with same email
				if (status === 409 && data.socials.length !== 0) {
					await this.setSocialAuthCallbackData(data, 'socials');
				}
				// User try to register with Facebook first time and callback responses
				if (status === 400 && data.socials.length === 0) {
					await this.setSocialAuthCallbackData(data, 'signup-social');
				}

				if (status === 422) {
					notify({
						body: data.message,
					});
				}

				if (status === 403) {
					this.setCheckedEmail(data.email);
					await router.replace({ name: 'signup-complete' });
				}

				if (status === 422 || status === 401) {
					await router.replace({ name: 'app-login' });
				}
			}
		},

		async loginToExisting(password: string) {
			try {
				this.error = null;

				if (this.socialAuthData?.signed_url) {
					const { data, status } = await authSocialLoginToExisting(
						this.socialAuthData.signed_url,
						this.socialAuthData,
						password
					);

					if (data && status === 200) await this.setAccountData(data);
				}
			} catch (error) {
				this.setLoginErrorData(this.socialAuthData!.email, error);
			} finally {
				this.loading = false;
			}
		},

		async registerSocial(email: string): Promise<void> {
			try {
				this.error = null;

				// CheckSocial responses with email from social network and not empty signed_url (mostly for Google new users)
				if (this.socialAuthData?.email && this.socialAuthData?.signed_url) {
					const { data, status } = await authSocialRegisterWithSignature(
						this.socialAuthData?.signed_url,
						{
							...this.socialAuthData,
							selected_country: this.country,
							selected_language: this.language,
						}
					);
					// Successfully registered new user that does not exists in DB with social account (mostly Google)
					if (status === 200 && data.token) {
						const appStore = useAppStore();
						await appStore.trackEvent(TrackingEvents.SIGN_UP);
						await appStore.trackEvent(TrackingEvents.SIGN_UP_CONFIRMED);
						this.setEmail(data.email);
						this.setToken(data.token);
						await router.replace({ name: 'surveys' });
					}
				}

				// IF we create account with social provider data, but after callback there were no email and signed url (mostly facebook)
				if (!this.socialAuthData?.email && !this.socialAuthData?.signed_url) {
					const { status } = await authSocialRegister(
						{
							...this.socialAuthData!,
							selected_country: this.country,
							selected_language: this.language,
						},
						email
					);

					this.setCheckedEmail(email);

					// user was not existed previous, we create absolutely new user, need verify email
					if (status === 200) {
						const appStore = useAppStore();
						await appStore.trackEvent(TrackingEvents.SIGN_UP);
						await router.replace({ name: 'signup-complete' });
					}
				}
			} catch (_error) {
				const { data, status } = getSocialAuthErrorResponse(_error);

				// register user with facebook, but
				if (status === 400) {
					// register user with facebook, but user already has Google registered account
					if (data.socials.length) {
						this.setUsedSocials(data.socials);
						this.setCheckedEmail(data.email);
						await router.replace({ name: 'socials' });
					}
					// Facebook user provided email, that was used to register via normal email flow, user should log in to previously created account
					if (!data.socials.length) {
						this.setLinkExistingToSocial(true);
						await this.setSocialAuthCallbackData(data, 'signin');
					}
				}
			}
		},

		async setUserPassword(password: string): Promise<void> {
			try {
				const { status, message } = await authSetPassword(password);
				if (status === 200) {
					notify({ body: message });
					const userStore = useUserStore();
					await userStore.fetchUserData();
				}
			} catch (error) {
				sendErrorInfo(error);
			}
		},

		setDeviceId(deviceId: string): void {
			this.deviceId = deviceId;
		},
	},
});
