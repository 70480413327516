import { Echo } from '@/utils/echo';
import { ref, watch, onUnmounted } from 'vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { UPDATED_BALANCE } from '@/locales/constants';
import {
	ACHIEVEMENT_UNLOCKED_MODAL,
	OFFERWALLS_REWARD_NOTIFICATION_MODAL,
	USER_LEVEL_MODAL,
	USER_STREAK_MODAL,
	OFFERWALLS_WELL_DONE_MODAL,
} from '@/constants/modals';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import type {
	AchievementUnlockedLevel,
	UserLevel,
	UserStreak,
	OfferwallRewardEvent,
	UserBalanceUpdateInfo,
	OfferwallTransactionDelayed,
} from '@/types';
import { MessageChannels, TrackingEvents } from '@/enums';
import { useAppStore } from '@/stores/app';

export const useAppPusherEventListener = (): void => {
	const modalStorage = useModalStorage();
	const { t } = useI18n();
	const echo = Echo.getInstance();
	const channelName = ref('');
	const { userId } = storeToRefs(useUserStore());
	const { userAuthToken } = storeToRefs(useAuthStore());
	const { isMobileApp } = useMobileApp();
	const userStore = useUserStore();
	const appStore = useAppStore();

	watch(
		() => userId.value && userAuthToken.value,
		async () => {
			if (!window.Echo && userAuthToken.value) {
				await echo.createConnection(userAuthToken.value);
			}

			if (userId.value && window.Echo) {
				channelName.value = `user-notifications-${userId.value}`;
				window.Echo.private(channelName.value)
					.listen(
						'.offerwall_transaction_delayed',
						(event: OfferwallTransactionDelayed) => {
							modalStorage.pushModal({
								name: OFFERWALLS_WELL_DONE_MODAL,
								data: event,
							});
							userStore.fetchOfferwallPending();
						}
					)
					.listen(
						// Balance update
						'.balance-updated',
						async (event: UserBalanceUpdateInfo) => {
							await userStore.fetchUserData();
							userStore.setCollectedCoins(event.new_balance);
							if (!event.is_silent) {
								notify({ body: t(UPDATED_BALANCE) });
							}
						}
					)
					.listen(
						// Level update
						'.user-survey-taken',
						(event: UserLevel) => {
							userStore.increaseOfferwallsMetaProgress();
							userStore.setLevel(event);
						}
					)
					.listen(
						// Level update
						'.user-level-up',
						(event: UserLevel) => {
							userStore.setLevel(event);
							modalStorage.pushModal({
								name: USER_LEVEL_MODAL,
								options: { ...event },
							});
						}
					)
					.listen(
						// Streak update
						'.user-streak-updated',
						(event: UserStreak) => {
							const userStreakLength = userStore.streaks;
							userStore.setStreak(event);
							if (userStreakLength === 0 && event.length === 1) {
								modalStorage.pushModal({ name: USER_STREAK_MODAL });
							}
						}
					)
					.listen(
						// Achievement level is unlocked/ready to claim
						'.achievement-level-ready-to-claim',
						(event: AchievementUnlockedLevel) => {
							modalStorage.pushModal({
								name: ACHIEVEMENT_UNLOCKED_MODAL,
								options: { ...event },
							});
						}
					)
					.listen(
						'.offerwall_transaction_completed',
						(event: OfferwallRewardEvent) => {
							userStore.setCollectedCoins(event.new_balance);
							modalStorage.pushModal({
								name: OFFERWALLS_REWARD_NOTIFICATION_MODAL,
								options: {
									...event,
									id: event.notification_id,
									channel: MessageChannels.NOTIFICATION,
								},
							});
						}
					)
					.listen('.offerwalls_unlocked', () => {
						userStore.fetchFeatures();
					})
					.listen(
						'.gtm_track_event',
						async ({
							event_name,
							event_name_adjust,
							params,
						}: {
							event_name: TrackingEvents;
							event_name_adjust: string;
							params: Record<string, any>;
						}) => {
							appStore.addLog(
								`WS GTM TRACK EVENT:::${event_name}:::${event_name_adjust}:::${JSON.stringify(params)}`
							);
							await appStore.trackEvent(event_name, params, event_name_adjust);
						}
					);
			}
		},
		{ immediate: true }
	);

	onUnmounted(() => {
		echo.removeConnections();
	});
};
