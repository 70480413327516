<script lang="ts" setup>
import { ref } from 'vue';
import { authChangeEmail } from '@/api';
import type { AxiosError } from 'axios';
import type { ErrorResponse } from '@/types';
import useVuelidate from '@vuelidate/core';
import {
	CHANGE_EMAIL,
	CONFIRM_EMAIL,
	EMAIL_TO_PREVIOUS,
	ENTER_NEW_EMAIL,
	NEW_EMAIL,
	PASSWORD,
	RESEND,
	SEND,
	UNLINK_SOCIALS,
} from '@/locales/constants';
import {
	PrimeButton,
	PrimeInput,
	PrimeCooldownButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import ResetPasswordButton from '@/components/common/ResetPasswordButton.vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import {
	PiEnvelopeDuotoneThin,
	PiHcWarning,
} from '@primeinsightsgroupllc/prime-icons';

const COOL_DOWN_SECONDS = 15;

type ChangeEmailForm = {
	email: string;
	password: string;
};

defineEmits<{
	(e: 'close-modal'): void;
}>();

const componentName = useComponentName();

const form = ref<ChangeEmailForm>({
	email: '',
	password: '',
});

const isRequestSent = ref(false);
const loading = ref(false);
const serverValidationResults = ref();
const cooldownButtonEl = ref<{ restartTimer: () => void } | null>(null);

const { email, password } = useValidationRules();
const validation = useVuelidate({ email, password }, form, {
	$autoDirty: false,
	$externalResults: serverValidationResults,
});

const {
	validate,
	getValidationFieldStatus,
	getValidationFieldText,
	isValidationError,
	clear,
} = useValidationUtils<ChangeEmailForm>(validation);

const handleChangeEmail = async () => {
	clear();
	await validate();
	if (isValidationError.value) return;
	try {
		loading.value = true;
		const { status } = await authChangeEmail(form.value);
		if (status === 200) {
			isRequestSent.value = true;
			cooldownButtonEl.value?.restartTimer();
		}
	} catch (error) {
		const errorData = (error as AxiosError).response?.data as ErrorResponse;
		serverValidationResults.value = {
			email: errorData.errors?.to_email,
			password: errorData.errors?.password,
		};
		sendErrorInfo(error, componentName);
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<ModalCommonContent v-if="!isRequestSent">
		<template #header>{{ $t(CHANGE_EMAIL) }}</template>
		<template #icon>
			<PiEnvelopeDuotoneThin size="100%" color="base-primary" />
		</template>
		<template #title>{{ $t(ENTER_NEW_EMAIL) }}</template>
		<template #inputs>
			<PrimeInput
				v-model="form.email"
				:placeholder="$t(NEW_EMAIL)"
				:invalid="!!getValidationFieldStatus('email')"
				:error-text="getValidationFieldText('email')?.toString()"
			/>
			<PrimeInput
				v-model="form.password"
				type="password"
				:placeholder="$t(PASSWORD)"
				:invalid="!!getValidationFieldStatus('password')"
				:error-text="getValidationFieldText('password')?.toString()"
			/>
			<ResetPasswordButton @click="$emit('close-modal')" />
			<div class="unlink-socials-message">
				<PiHcWarning color="white" />
				<span>{{ $t(UNLINK_SOCIALS) }}</span>
			</div>
		</template>
		<template #actions>
			<PrimeButton full-width :label="$t(SEND)" @click="handleChangeEmail" />
			<PrimeLoader v-if="loading" />
		</template>
	</ModalCommonContent>
	<ModalCommonContent v-else>
		<template #header>{{ $t(CONFIRM_EMAIL) }}</template>
		<template #icon>
			<PiEnvelopeDuotoneThin size="100%" color="base-primary" />
		</template>
		<template #title>{{ $t(EMAIL_TO_PREVIOUS) }}</template>
		<template #actions>
			<PrimeCooldownButton
				ref="cooldownButtonEl"
				:text="$t(RESEND)"
				:timer="COOL_DOWN_SECONDS"
				@click="handleChangeEmail"
			/>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss" scoped>
.unlink-socials-message {
	margin-top: 1.125rem;
	width: 100%;
	padding: 0.5rem;
	font-weight: 500;
	font-size: 0.75rem;
	border-radius: 12px;
	border: 1px solid var(--grey-cool-300-borders, #363840);
	box-shadow:
		0px 1px 3px 0px rgba(63, 63, 68, 0.15),
		0px 0px 0px 1px rgba(63, 63, 68, 0.05);
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: flex-start;
}
</style>
