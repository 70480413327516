import API from '@/api';
import type {
	MessageResponse,
	StatusResponse,
	UserAccount,
	UserMeta,
} from '@/types';

export const getUser = async (): Promise<UserAccount> => {
	const { data } = await API.get<UserAccount>('/api/user');
	return data;
};

export const deleteUser = async (
	password: string
): Promise<MessageResponse & StatusResponse> => {
	const response = await API.patch<MessageResponse>('/api/user', {
		password,
	});

	return {
		message: response.data.message,
		status: response.status,
	};
};

export const sendDeviceMaid = async (maid: string): Promise<number> => {
	const { status } = await API.post<StatusResponse>('/api/user-maids', {
		maid,
	});
	return status;
};

export const sendDeviceId = async (deviceId: string): Promise<void> => {
	await API.post<MessageResponse>('/api/user-installations', {
		installation_id: deviceId,
	});
};

export const getUserMeta = async (): Promise<UserMeta> => {
	const { data } = await API.get<UserMeta>('/api/user-meta');
	return data;
};
